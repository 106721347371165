import logo from './logo.png';
import './App.css';
import {useState} from "react";

function App() {
  const [topic, setTopic] = useState("");
  const [ideas, setIdeas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIdeaIndex, setCurrentIdeaIndex] = useState(0);

  const generateIdeas = () => {
      setIsLoading(true);
      setCurrentIdeaIndex(0);

      const webhookUrl = "https://n8n-q4kcow4.internal.zrcn.dev/webhook/511b857d-565f-41a3-8d2f-7f51ed1479fe";

      try{
          const response = fetch(webhookUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  topic: topic,
              }),
          });

          response.then(response => response.json())
              .then(data => {
                  console.log(data);
                  setIdeas(data.data);
              })
              .finally(() => {
                  setIsLoading(false);
              });
      } catch (e) {
          alert("Error generating ideas: " + e);
      }
  }

  const handleGenerateButton = () => { generateIdeas() };

  const nextIdea = () => {
    setCurrentIdeaIndex((currentIdeaIndex + 1) % ideas.length);
  };

  const prevIdea = () => {
    setCurrentIdeaIndex((currentIdeaIndex - 1 + ideas.length) % ideas.length);
  };

  const saveToNotion = () => {
    const notionUrl = "https://n8n-q4kcow4.internal.zrcn.dev/webhook/abc26111-be0d-46f3-9d77-cf1867049830";
    try{
        fetch(notionUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                topic: topic,
                idea: ideas[currentIdeaIndex],
            }),
        });

        nextIdea();
    }catch (e) {
        alert("Error saving to Notion: " + e);
    }
  };

  return (
    <div className="App">
        <img src={logo} width={100} alt="logo" />
        <h1>Idea Generator</h1>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 16}}>
        <textarea id="idea-input" placeholder="Enter your topic here..." rows={5} value={topic} onChange={(e) => setTopic(e.target.value)}></textarea>
        <button id="generate-button" onClick={handleGenerateButton}>Generate Ideas</button>
      </div>
        {isLoading && <div>Loading...</div>}
        {!isLoading && ideas && ideas[currentIdeaIndex] && <div>
            <div>
              <h2>{ideas[currentIdeaIndex].title}</h2>
              <p><b>Description:</b> {ideas[currentIdeaIndex].description}</p>
              <p><b>Image Info:</b> {ideas[currentIdeaIndex].imageInfo}</p>
              <p><b>Predicted Audience:</b> {ideas[currentIdeaIndex].predictedAudience}</p>
              <p><b>Connected Services:</b> {ideas[currentIdeaIndex].connectedServices}</p>
              <p><b>Content:</b> {ideas[currentIdeaIndex].content}</p>
            </div>
            <div style={{display: "flex", justifyContent: "center", gap: 16}}>
                <button onClick={prevIdea}>Prev</button>
                <button onClick={saveToNotion}>Save to Notion</button>
                <button onClick={nextIdea}>Next</button>
            </div>
        </div>}
    </div>
  );
}

export default App;
